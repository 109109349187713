// src/SEOContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const SEOContext = createContext();

export const SEOProvider = ({ children }) => {
  const [seo, setSeo] = useState({
    title: '',
    description: '',
    structuredData: null,
  });

  useEffect(() => {
    if (seo.title) {
      document.title = seo.title;
    }
    if (seo.description) {
      let metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", seo.description);
      } else {
        metaDescription = document.createElement('meta');
        metaDescription.name = "description";
        metaDescription.content = seo.description;
        document.head.appendChild(metaDescription);
      }
    }
    if (seo.structuredData) {
      let existingScript = document.querySelector('script[type="application/ld+json"]');
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(seo.structuredData);
      document.head.appendChild(script);
    }

    return () => {
      // Cleanup meta tags and JSON-LD when context is updated
      if (seo.description) {
        let metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          document.head.removeChild(metaDescription);
        }
      }
      if (seo.structuredData) {
        let existingScript = document.querySelector('script[type="application/ld+json"]');
        if (existingScript) {
          document.head.removeChild(existingScript);
        }
      }
    };
  }, [seo]);

  return (
    <SEOContext.Provider value={{ seo, setSeo }}>
      {children}
    </SEOContext.Provider>
  );
};

export const useSEO = () => useContext(SEOContext);
