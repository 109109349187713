import { useEffect } from 'react';
import { useSEO } from '../context/seoContext';

const SEO = ({ title, description, structuredData }) => {
  const { setSeo } = useSEO();

  useEffect(() => {
    setSeo({
      title,
      description,
      structuredData,
    });
  }, [title, description, structuredData, setSeo]);

  return null;
};

export default SEO;
