import React, { useContext } from 'react';
import { Select, MenuItem, Typography } from '@mui/material';

import { languageOptions } from '../languages/translations';
import { LanguageContext } from '../languages/languages';
import {useTheme} from "@mui/material"

export  const   LanguageSelector = () =>  {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const handleLanguageChange = e => userLanguageChange(e.target.value);
  const theme = useTheme()
  return (

    <Select
    sx={{
      // color: theme.palette.primary.main, // Убедитесь, что используете корректное значение из темы
      fontSize: '12pt',
      borderRadius: '40px',
      ".MuiOutlinedInput-notchedOutline": {
        border: 'none', // Полностью убираем границу
      },
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: 'none', // Убираем границу при наведении
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: 'none', // Убираем границу при фокусе
      },
    }}

    MenuProps={{
      PaperProps: {
        sx: {
          color:theme.palette.primary,
          bgcolor: theme.palette.primary.contrastText,
          '& .MuiMenuItem-root': {
            padding: 2,
            width:200,
          },
        },
      },
    }}
    
      onChange={handleLanguageChange}
      value={userLanguage}
    >
      {Object.entries(languageOptions).map(([id, name]) => (
        <MenuItem key={id} value={id}>
          <Typography variant='body'> {name}</Typography> </MenuItem>
      ))}
    </Select>
  );
};