
import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PassportIcon = (props) => {
    return (
<SvgIcon {...props}>
	<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_223_60)">
    <path d="M15.6769 10.6262C15.7719 11.0416 15.843 11.5037 15.8902 11.9886C16.3756 12.0353 16.8372 12.1074 17.2526 12.2024C17.163 11.7764 17.0266 11.3806 16.8547 11.0235C16.4987 10.8516 16.1024 10.7153 15.6769 10.6262Z" fill={props.color}/>
    <path d="M15.5559 16.6833C15.4599 16.9985 15.348 17.2733 15.2191 17.4929C15.7093 17.302 16.1491 16.9327 16.5014 16.434C16.2032 16.5422 15.8854 16.6245 15.5559 16.6833Z" fill={props.color}/>
    <path d="M17.3805 13.4264C17.3805 13.1653 17.3608 12.908 17.3285 12.6597C16.9369 12.5595 16.4664 12.4751 15.9252 12.4194C15.947 12.751 15.9581 13.09 15.9581 13.4264C15.9581 13.7648 15.947 14.1028 15.9252 14.4344C16.4664 14.3787 16.9364 14.2949 17.3285 14.1935C17.3608 13.9452 17.3805 13.6895 17.3805 13.4264Z" fill={props.color}/>
    <path d="M13.8168 16.748C14.0168 17.3279 14.2519 17.6463 14.453 17.6463C14.6524 17.6463 14.8885 17.3274 15.0885 16.748C14.8795 16.7687 14.6678 16.7804 14.453 16.7804C14.2376 16.7799 14.0248 16.7677 13.8168 16.748Z" fill={props.color}/>
    <path d="M15.6769 16.2281C16.1024 16.1379 16.4987 16.0021 16.8558 15.8302C17.0271 15.4732 17.164 15.0769 17.2537 14.6514C16.8383 14.7474 16.3767 14.8185 15.8912 14.8652C15.8435 15.3506 15.7719 15.8127 15.6769 16.2281Z" fill={props.color}/>
    <path d="M16.4749 17.1274C17.1815 16.7401 17.7662 16.156 18.1524 15.4498C17.8749 15.7146 17.5428 15.948 17.1688 16.1443C16.9752 16.5173 16.7401 16.8489 16.4749 17.1274Z" fill={props.color}/>
    <path d="M12.0496 11.0246C11.8777 11.3811 11.7414 11.7774 11.6522 12.2035C12.066 12.1074 12.5292 12.0369 13.0141 11.9891C13.0613 11.5042 13.134 11.0416 13.2279 10.6272C12.8024 10.7153 12.4066 10.8516 12.0496 11.0246Z" fill={props.color}/>
    <path d="M15.2196 16.3035C15.3204 15.9119 15.4053 15.4413 15.4605 14.8997C15.1289 14.9219 14.7899 14.9331 14.4535 14.9331C14.115 14.9331 13.7771 14.9219 13.4465 14.8997C13.5017 15.4413 13.5861 15.9114 13.6858 16.3035C13.9357 16.3353 14.1914 16.3544 14.4535 16.3544C14.7145 16.3544 14.9702 16.3353 15.2196 16.3035Z" fill={props.color}/>
    <path d="M11.5758 12.6597C11.5435 12.9085 11.5249 13.1648 11.5249 13.4269C11.5249 13.689 11.5435 13.9452 11.5758 14.1946C11.9674 14.2948 12.438 14.3792 12.9796 14.4349C12.9568 14.1033 12.9462 13.7648 12.9462 13.4269C12.9462 13.09 12.9574 12.7515 12.9796 12.4199C12.438 12.4751 11.9679 12.5589 11.5758 12.6597Z" fill={props.color}/>
    <path d="M12.4035 16.4345C12.7558 16.9332 13.1961 17.3019 13.6853 17.4935C13.5579 17.2738 13.446 16.9985 13.3489 16.6839C13.0189 16.6244 12.7022 16.5422 12.4035 16.4345Z" fill={props.color}/>
    <path d="M17.4606 15.4758C17.9582 15.1235 18.3275 14.6842 18.5185 14.194C18.2988 14.323 18.0235 14.4349 17.7099 14.5309C17.651 14.8614 17.5667 15.1771 17.4606 15.4758Z" fill={props.color}/>
    <path d="M13.2285 16.2281C13.133 15.8127 13.0619 15.3506 13.0147 14.8657C12.5298 14.819 12.0677 14.7474 11.6528 14.6519C11.7419 15.0774 11.8782 15.4737 12.0501 15.8318C12.4067 16.0021 12.8024 16.1379 13.2285 16.2281Z" fill={props.color}/>
    <path d="M13.4073 12.3828C13.385 12.7101 13.3707 13.0571 13.3707 13.4274C13.3707 13.7977 13.385 14.1447 13.4073 14.4731C13.7346 14.4954 14.0816 14.5092 14.453 14.5092C14.8222 14.5092 15.1692 14.4954 15.4987 14.4731C15.5215 14.1447 15.5337 13.7988 15.5337 13.4274C15.5337 13.0576 15.5215 12.7101 15.4987 12.3828C15.1692 12.36 14.8233 12.3456 14.453 12.3456C14.0821 12.3456 13.7351 12.36 13.4073 12.3828Z" fill={props.color}/>
    <path d="M10.7524 15.4498C11.1397 16.156 11.7233 16.7401 12.4295 17.1274C12.1653 16.8489 11.9308 16.5173 11.7361 16.1438C11.3626 15.9485 11.031 15.7146 10.7524 15.4498Z" fill={props.color}/>
    <path d="M11.4448 15.4758C11.3376 15.1777 11.2549 14.8614 11.196 14.5309C10.8803 14.4349 10.6055 14.323 10.3853 14.194C10.5779 14.6842 10.945 15.1235 11.4448 15.4758Z" fill={props.color}/>
    <path d="M12.429 9.72638C11.7228 10.1137 11.1392 10.6978 10.7519 11.4034C11.0299 11.1387 11.3615 10.9047 11.7355 10.71C11.9303 10.3365 12.1647 10.0049 12.429 9.72638Z" fill={props.color}/>
    <path d="M11.0999 13.4263C11.0999 13.2109 11.1111 12.9987 11.1323 12.7913C10.5519 12.9913 10.2341 13.2253 10.2341 13.4263C10.2341 13.6274 10.5524 13.8624 11.1323 14.0625C11.1111 13.855 11.0999 13.6428 11.0999 13.4263Z" fill={props.color}/>
    <path d="M19.3801 4.15411H9.51789C8.37139 4.15411 7.45038 5.08574 7.45038 6.23224V21.9335C7.45038 23.0806 8.37139 24.0005 9.51789 24.0005H19.3801C20.5266 24.0005 21.4614 23.0822 21.4614 21.9335V6.23224C21.4614 5.08574 20.5266 4.15411 19.3801 4.15411ZM14.453 18.0712C11.8915 18.0712 9.80863 15.9883 9.80863 13.4269C9.80863 10.866 11.8915 8.78307 14.453 8.78307C17.0128 8.78307 19.0962 10.8665 19.0962 13.4269C19.0962 15.9889 17.0128 18.0712 14.453 18.0712Z" fill={props.color}/>
    <path d="M17.8059 13.4263C17.8059 13.6428 17.7943 13.855 17.7725 14.0625C18.354 13.8624 18.6707 13.6274 18.6707 13.4263C18.6707 13.2263 18.3535 12.9913 17.7725 12.7913C17.7937 12.9993 17.8059 13.2115 17.8059 13.4263Z" fill={props.color}/>
    <path d="M11.4448 11.3779C10.9456 11.7297 10.5779 12.17 10.3853 12.6597C10.6055 12.5313 10.8808 12.4188 11.196 12.3234C11.2543 11.9928 11.3382 11.6772 11.4448 11.3779Z" fill={props.color}/>
    <path d="M16.5019 10.4193C16.1496 9.92055 15.7098 9.55236 15.2196 9.35977C15.3485 9.58048 15.4604 9.8553 15.5565 10.1699C15.8854 10.2293 16.2032 10.3126 16.5019 10.4193Z" fill={props.color}/>
    <path d="M16.4749 9.72638C16.7401 10.0049 16.9741 10.3365 17.1693 10.71C17.5428 10.9047 17.8749 11.1387 18.1529 11.4034C17.7657 10.6978 17.1815 10.1137 16.4749 9.72638Z" fill={props.color}/>
    <path d="M17.4606 11.3779C17.5667 11.6766 17.6505 11.9928 17.7099 12.3234C18.024 12.4194 18.2988 12.5313 18.5185 12.6597C18.3275 12.17 17.9587 11.7297 17.4606 11.3779Z" fill={props.color}/>
    <path d="M13.3489 10.1704C13.446 9.85528 13.5579 9.58046 13.6853 9.36029C13.1961 9.55235 12.7558 9.92107 12.4035 10.4198C12.7016 10.3126 13.0189 10.2293 13.3489 10.1704Z" fill={props.color}/>
    <path d="M15.0885 10.1062C14.8885 9.52583 14.6524 9.20804 14.453 9.20804C14.2519 9.20804 14.0168 9.52636 13.8168 10.1062C14.0253 10.0855 14.2376 10.0739 14.453 10.0739C14.6678 10.0744 14.88 10.0855 15.0885 10.1062Z" fill={props.color}/>
    <path d="M13.6853 10.5508C13.5855 10.9424 13.5001 11.413 13.446 11.9541C13.7765 11.9318 14.1155 11.9207 14.4529 11.9207C14.7898 11.9207 15.1283 11.9318 15.4599 11.9541C15.4047 11.413 15.3198 10.9424 15.219 10.5508C14.9697 10.5185 14.7145 10.4999 14.4529 10.4999C14.1909 10.4999 13.9341 10.5185 13.6853 10.5508Z" fill={props.color}/>
    <path d="M4.48359 17.7826V2.08131C4.48359 2.01021 4.53877 1.94549 4.6141 1.94549H14.4763C14.5538 1.94549 14.6042 2.0118 14.6042 2.08131V3.03309H16.5496V2.08131C16.5496 0.93481 15.6233 0 14.4763 0H4.6141C3.46761 0 2.53864 0.93481 2.53864 2.08131V17.7821C2.53864 18.9291 3.46761 19.8459 4.6141 19.8459H6.19776V17.9004H4.6141C4.53824 17.9009 4.48359 17.8553 4.48359 17.7826Z" fill={props.color}/>
    </g><defs><clipPath id="clip0_223_60"><rect width="24" height="24" fill={props.color}/></clipPath></defs></svg>
	 </SvgIcon>
    )}

  const CompanyIcon = (props) => {
	return (
		<svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5 0H3C2.175 0 1.425 0.337 0.881 0.881C0.337 1.425 0 2.175 0 3V5C0 5.825 0.337 6.575 0.881 7.119C1.425 7.663 2.175 8 3 8H5C5.825 8 6.575 7.663 7.119 7.119C7.663 6.575 8 5.825 8 5V3C8 2.175 7.663 1.425 7.119 0.881C6.575 0.337 5.825 0 5 0ZM15 0H13C12.175 0 11.425 0.337 10.881 0.881C10.337 1.425 10 2.175 10 3V5C10 5.825 10.337 6.575 10.881 7.119C11.425 7.663 12.175 8 13 8H15C15.825 8 16.575 7.663 17.119 7.119C17.663 6.575 18 5.825 18 5V3C18 2.175 17.663 1.425 17.119 0.881C16.575 0.337 15.825 0 15 0ZM5 10H3C2.175 10 1.425 10.337 0.881 10.881C0.337 11.425 0 12.175 0 13V15C0 15.825 0.337 16.575 0.881 17.119C1.425 17.663 2.175 18 3 18H5C5.825 18 6.575 17.663 7.119 17.119C7.663 16.575 8 15.825 8 15V13C8 12.175 7.663 11.425 7.119 10.881C6.575 10.337 5.825 10 5 10ZM15 10H13C12.175 10 11.425 10.337 10.881 10.881C10.337 11.425 10 12.175 10 13V15C10 15.825 10.337 16.575 10.881 17.119C11.425 17.663 12.175 18 13 18H15C15.825 18 16.575 17.663 17.119 17.119C17.663 16.575 18 15.825 18 15V13C18 12.175 17.663 11.425 17.119 10.881C16.575 10.337 15.825 10 15 10Z" fill={props.color}/></svg>
	)
}

const TasksIcon = (props) => {
	return (
		<svg width={22.5} height={20.99} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3 15.5H6C6.76925 15.5 7.40326 16.079 7.48991 16.8251L7.5 17V20C7.5 20.7693 6.92093 21.4033 6.17493 21.4899L6 21.5H3C2.23075 21.5 1.59674 20.921 1.51009 20.1749L1.5 20V17C1.5 16.2307 2.07907 15.5967 2.82507 15.5101L3 15.5H6H3ZM21 17C21.8284 17 22.5 17.6716 22.5 18.5C22.5 19.3284 21.8284 20 21 20H12C11.1716 20 10.5 19.3284 10.5 18.5C10.5 17.6716 11.1716 17 12 17H21ZM6 17H3V20H6V17ZM6 8C6.82842 8 7.5 8.67158 7.5 9.5V12.5C7.5 13.3284 6.82842 14 6 14H3C2.17158 14 1.5 13.3284 1.5 12.5V9.5C1.5 8.67158 2.17158 8 3 8H6ZM21 9.5C21.8284 9.5 22.5 10.1716 22.5 11C22.5 11.7692 21.921 12.4033 21.1749 12.4899L21 12.5H12C11.1716 12.5 10.5 11.8284 10.5 11C10.5 10.2308 11.0791 9.59674 11.8251 9.51009L12 9.5H21ZM6 9.5H3V12.5H6V9.5ZM7.16199 0.83921C7.56139 1.23861 7.59769 1.86361 7.27092 2.30402L7.16199 2.4302L3.42049 6.17169C3.20952 6.38268 2.92336 6.5012 2.625 6.5012C2.38631 6.5012 2.15543 6.42535 1.96461 6.28698L1.82951 6.17169L0.329505 4.67169C-0.109835 4.23236 -0.109835 3.52005 0.329505 3.0807C0.728905 2.68131 1.3539 2.645 1.79432 2.97178L1.92049 3.0807L2.625 3.78521L5.571 0.83921C6.01033 0.39987 6.72264 0.399869 7.16199 0.83921ZM21 2C21.8284 2 22.5 2.67158 22.5 3.5C22.5 4.26925 21.921 4.90326 21.1749 4.98991L21 5H12C11.1716 5 10.5 4.32842 10.5 3.5C10.5 2.73075 11.0791 2.09674 11.8251 2.01009L12 2H21Z" fill={props.color}/></svg>
	)
}

const WarehouseIcon = (props) => {
	return (
		<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M23.9691 22.9801V5.9954C23.9691 5.58844 23.7623 5.2305 23.4078 5.0785L12.3932 0.0772256C12.1372 -0.0257419 11.8319 -0.0257419 11.5759 0.0772256L0.561313 5.0785C0.201876 5.2305 0 5.58844 0 5.9954V22.9801C0 23.5391 0.462837 24 1.02415 24C1.58546 24 2.0483 23.5391 2.0483 22.9801V6.65734L12.0879 2.11696L22.0734 6.65734V22.9801C22.0734 23.5391 22.5362 24 23.0976 24C23.6589 24 24.1266 23.544 23.9691 22.9801Z" fill={props.color}/>
    <path d="M15.3672 14.0024C15.9285 14.0024 16.3913 13.5415 16.3913 12.9825V6.40238C16.3913 5.84341 15.9285 5.38251 15.3672 5.38251H8.75944C8.19813 5.38251 7.73529 5.84341 7.73529 6.40238V12.9825C7.73529 13.5415 8.19813 14.0024 8.75944 14.0024H15.3672ZM9.78359 7.47618H14.343V12.0166H9.78359V7.47618V7.47618Z" fill={props.color}/>
    <path d="M10.4483 15.3802H3.84056C3.27924 15.3802 2.81641 15.8411 2.81641 16.4V22.9801C2.81641 23.5391 3.27924 24 3.84056 24H10.4483C11.0096 24 11.4724 23.5391 11.4724 22.9801V16.4C11.4724 15.8411 11.0096 15.3802 10.4483 15.3802ZM9.42415 22.0142H4.86471V17.4738H9.42415V22.0142Z" fill={props.color}/>
    <path d="M20.2811 15.3802H13.6734C13.1121 15.3802 12.6492 15.8411 12.6492 16.4V22.9801C12.6492 23.5391 13.1121 24 13.6734 24H20.2811C20.8424 24 21.3053 23.5391 21.3053 22.9801V16.4C21.3053 15.8411 20.8474 15.3802 20.2811 15.3802ZM19.257 22.0142H14.6975V17.4738H19.257V22.0142V22.0142Z" fill={props.color}/></svg>
	)
}


const UsersIcon = (props) => {
	return (
		<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3.6 10.8C4.92375 10.8 6 9.72376 6 8.40001C6 7.07626 4.92375 6.00001 3.6 6.00001C2.27625 6.00001 1.2 7.07626 1.2 8.40001C1.2 9.72376 2.27625 10.8 3.6 10.8ZM20.4 10.8C21.7238 10.8 22.8 9.72376 22.8 8.40001C22.8 7.07626 21.7238 6.00001 20.4 6.00001C19.0763 6.00001 18 7.07626 18 8.40001C18 9.72376 19.0763 10.8 20.4 10.8ZM21.6 12H19.2C18.54 12 17.9438 12.2663 17.5088 12.6975C19.02 13.5263 20.0925 15.0225 20.325 16.8H22.8C23.4638 16.8 24 16.2638 24 15.6V14.4C24 13.0763 22.9238 12 21.6 12ZM12 12C14.3213 12 16.2 10.1213 16.2 7.80001C16.2 5.47876 14.3213 3.60001 12 3.60001C9.67875 3.60001 7.8 5.47876 7.8 7.80001C7.8 10.1213 9.67875 12 12 12ZM14.88 13.2H14.5688C13.7888 13.575 12.9225 13.8 12 13.8C11.0775 13.8 10.215 13.575 9.43125 13.2H9.12C6.735 13.2 4.8 15.135 4.8 17.52V18.6C4.8 19.5938 5.60625 20.4 6.6 20.4H17.4C18.3938 20.4 19.2 19.5938 19.2 18.6V17.52C19.2 15.135 17.265 13.2 14.88 13.2ZM6.49125 12.6975C6.05625 12.2663 5.46 12 4.8 12H2.4C1.07625 12 0 13.0763 0 14.4V15.6C0 16.2638 0.53625 16.8 1.2 16.8H3.67125C3.9075 15.0225 4.98 13.5263 6.49125 12.6975Z" fill={props.color}/></svg>
	)
}



const Logo = (props) => { 
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <path fill={props.Color} d="M7 8h8v24H7zm11 0h8v16h-8V8Z"/>
    </svg>
    )
}


const LogoRainbow = (props) => { 
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
<defs>
      <linearGradient x1 = "50%" y1="100%"  x2 = "10%" y2="0%" id="front-rainbow-gradient">
        <stop offset="0%" stopColor="rgba(0, 0, 255, 1)"/>
        <stop offset="50%" stopColor="rgba(0, 255, 0, 1)"/>
        <stop offset="100%" stopColor="rgba(255, 0, 10, 1)"/>
      </linearGradient>
          </defs>
      <path  fill="url(#front-rainbow-gradient)" fillOpacity=".65"  d="M7 8h8v24H7zm11 0h8v16h-8V8Z"/>
  </svg>
    )}

const LogoW = (props) => {
return (
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
  <g>
  {/* <path fill="#000" d="M0 0h32v32H0z"/> */}
  <path fill="#fff" d="M7 8h8v24H7zm11 0h8v16h-8V8Z"/>
  </g>
</svg>
)}


const LogoB = (props) => {
return (
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
  <g>
    <path fill="#fff" d="M0 0h32v32H0z"/>
    <path fill="#000" d="M7 8h8v24H7zm11 0h8v16h-8V8Z"/>
  </g>
  {/* <defs>
    <clipPath id="a">
      <path fill="#fff" d="M0 0h32v32H0z"/>
    </clipPath>
  </defs> */}
</svg>)}


const LogoBig = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="180" height="180" fill="none" viewBox="0 0 180 180">
    <path fill={props.Color} d="M29 31h54v149H29V31Zm70 0h54v100H99V31Z"/>
  </svg>
  )}


  const LogoBigRainbow = (props) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="180" height="180" fill="none" viewBox="0 0 180 180">
        <defs>
            <linearGradient x1 = "50%" y1="100%"  x2 = "10%" y2="0%" id="front-rainbow-gradient">
        {/* <stop offset="0%" stopColor="rgba(0, 0, 255, 1)"/>
        <stop offset="50%" stopColor="rgba(0, 255, 0, 1)"/>
        <stop offset="100%" stopColor="rgba(255, 0, 10, 1)"/> */}
         <stop offset="0%" stopColor="rgba(255, 0, 0, 1)"/>
        <stop offset="10%" stopColor="rgba(255, 154, 0, 1)"/>
        <stop offset="20%" stopColor="rgba(208, 222, 33, 1)"/>
        <stop offset="30%" stopColor="rgba(79, 220, 74, 1)"/>
        <stop offset="40%" stopColor="rgba(63, 218, 216, 1)"/>
        <stop offset="50%" stopColor="rgba(47, 201, 226, 1)"/>
        <stop offset="60%" stopColor="rgba(28, 127, 238, 1)"/>
        <stop offset="70%" stopColor="rgba(95, 21, 242, 1)"/>
        <stop offset="80%" stopColor="rgba(186, 12, 248, 1)"/>
        <stop offset="90%" stopColor="rgba(251, 7, 217, 1)"/>
        <stop offset="100%" stopColor="rgba(255, 0, 0, 1)"/>
            </linearGradient>
          </defs>
      <path fill="url(#front-rainbow-gradient)" fillOpacity=".65" d="M29 31h54v149H29V31Zm70 0h54v100H99V31Z"/>
    </svg>
    )}







export { UsersIcon };
export { WarehouseIcon };
export { TasksIcon };
export {PassportIcon}
export { CompanyIcon };
export {LogoW};
export {LogoB};
export {Logo};
export {LogoBig};
export {LogoRainbow};
export {LogoBigRainbow};
