import {Toolbar, Typography, IconButton, Divider} from "@mui/material"
import {Link, useLocation} from "react-router-dom"
import { useUser } from "../context/user"
import { useTheme } from '@mui/material';
import { LogoW, LogoRainbow } from "../functions/SvgIcons";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { LanguageSelector } from "../functions/LanguageSelector" 
import { Text } from "../languages/languages";
import { MailOutline } from "@mui/icons-material";



const LoginToolbar = () => {
    let location = useLocation()
    let theme = useTheme();
    let isDark = theme?.state?.isDark;
    
    let user = useUser()
    console.log("APPBAR", user);
    let link = "/";
    if (user && user.user && user.user.token) {
      console.log("title", user.user.token);
  
       link = "/desktop";
  } else {
       link = "/";
  }

      return (
    <Toolbar sx={{ display: 'flex', flexGrow: 1 }}>
    {location.pathname !== "/" ? isDark ? <LogoW /> : <LogoRainbow /> : <div></div>}
    <Typography variant='h2' mt={0} component={Link} to={link} sx={{ fontWeight: 'bold' }} style={{ textDecoration: 'inherit', color: 'inherit' }}>micro</Typography>
    <Typography variant='h2' mt={0} component={Link} to={link} sx={{ fontWeight: 'lighter' }} style={{ textDecoration: 'inherit', color: 'inherit' }}>business</Typography>
    <Typography variant='caption' component={Link} to={link}  sx={{ display: 'flex', flexGrow: 1, fontStyle: 'italic', fontWeight: 'lighter' }} mt={"1.84rem"} ml={-6.5} style={{ textDecoration: 'inherit', color: 'inherit' }}>software </Typography>
    <LanguageSelector />
    <Divider sx={{ width: "16px" }} />
    {link ==="/" ?
      <div>
        <Typography variant='body' mt={0} component={Link} to="/registration" sx={{ fontWeight: 'lighter' }} style={{ textDecoration: 'inherit', color: 'inherit' }}><Text tid={"Registration"} /></Typography>
        <Typography variant='body' mt={0} sx={{ fontWeight: 'lighter' }} style={{ textDecoration: 'inherit', color: 'inherit' }}>  /  </Typography>
        <Typography variant='body' mt={0} component={Link} to="/sign" sx={{ fontWeight: 'lighter' }} style={{ textDecoration: 'inherit', color: 'inherit' }}><Text tid={"Sign in"} /></Typography>
      </div>
      :
      <IconButton component={Link} to="/desktop">
        <DashboardOutlinedIcon />
      </IconButton>
    }
  </Toolbar>
  )}



  const DesktopToolbar = () => {
    let location = useLocation()
    let theme = useTheme();
    let isDark = theme?.state?.isDark;
    
    let user = useUser()
      return (
    <Toolbar sx={{ display: 'flex', flexGrow: 1 }}>
    {location.pathname !== "/" ? isDark ? <LogoW /> : <LogoRainbow /> : <div></div>}
    <Typography variant='h2' mt={0} component={Link} to="/desktop" sx={{ fontWeight: 'bold' }} style={{ textDecoration: 'inherit', color: 'inherit' }}>micro</Typography>
    <Typography variant='h2' mt={0} component={Link} to="/desktop" sx={{ fontWeight: 'lighter' }} style={{ textDecoration: 'inherit', color: 'inherit' }}>business</Typography>
    <Typography variant='caption' component={Link} to="/desktop" sx={{ display: 'flex', flexGrow: 1, fontStyle: 'italic', fontWeight: 'lighter' }} mt={"1.84rem"} ml={-6.5} style={{ textDecoration: 'inherit', color: 'inherit' }}>software </Typography>
    {/* <LanguageSelector /> */}
    <Divider sx={{ width: "16px" }} />
    {location.pathname === "/" ? 
    <IconButton component={Link} to="/desktop">
        <DashboardOutlinedIcon />
      </IconButton> : 

      <IconButton component={Link} to="/desktop/messages">
      <MailOutline />
      </IconButton>
      }
    {/* {user.user.token === undefined ?
      <div>
        <Typography variant='body' mt={0} component={Link} to="/registration" sx={{ fontWeight: 'lighter' }} style={{ textDecoration: 'inherit', color: 'inherit' }}><Text tid={"Registration"} /></Typography>
        <Typography variant='body' mt={0} sx={{ fontWeight: 'lighter' }} style={{ textDecoration: 'inherit', color: 'inherit' }}>  /  </Typography>
        <Typography variant='body' mt={0} component={Link} to="/sign" sx={{ fontWeight: 'lighter' }} style={{ textDecoration: 'inherit', color: 'inherit' }}><Text tid={"Sign in"} /></Typography>
      </div>
      :
      <IconButton component={Link} to="/desktop">
        <DashboardOutlinedIcon />
      </IconButton>
    } */}
  </Toolbar>
  )}


  const MyToolbar = () => {
    let user = useUser();

    // Проверяем токен
    if (user && user.user && user.user.token) {
        return <DesktopToolbar />;
    } else {
        return <LoginToolbar />;
    }
};

  export {MyToolbar}