import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import {LanguageProvider}from "./languages/languages"
import { UserProvider } from './context/user';
// import { WebsocketProvider } from './context/websocket';
import { SEOProvider } from './context/seoContext';
import { DatabaseProvider } from './context/DatabaseContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
  <DatabaseProvider>
  <UserProvider>
  {/* <WebsocketProvider> */}
  <LanguageProvider>
  <SEOProvider>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </SEOProvider>
  </LanguageProvider>
  {/* </WebsocketProvider> */}
  </UserProvider>
  </DatabaseProvider>
  </React.StrictMode>
);

reportWebVitals(console.log);
