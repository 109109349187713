import { useState, createContext, useContext, useEffect } from 'react';
import { languageOptions, dictionaryList } from './translations/index';
import { useUser } from '../context/user';


// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'en',
  dictionary: dictionaryList.en,
  userLanguageChange: () => {}
});

export const useLanguage = () => useContext(LanguageContext);


// it provides the language context to app
export const LanguageProvider = ({ children }) => {
  const user = useUser()
let defaultLanguage = "en"
  try {
     defaultLanguage = user.user.language
  } catch (error) {
     defaultLanguage = "en"
  }  
  defaultLanguage = navigator.language.substring(0,2) || navigator.userLanguage.substring(0,2);
  if (dictionaryList[defaultLanguage] == null) {defaultLanguage="en"}

  
  const [userLanguage, setUserLanguage] = useState(defaultLanguage);
  
  useEffect(() => {
    document.documentElement.lang = userLanguage;
  }, [userLanguage]);


  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: selected => {
      const newLanguage = languageOptions[selected] ? selected : 'en'
      setUserLanguage(newLanguage);
      // window.?.setItem('lang', newLanguage);
      user.SetUserData("language", newLanguage)
    },
    // userLanguageSelect: selected => {
    //   const newLanguage = languageOptions[selected] ? selected : 'en'
    //   setUserLanguage(newLanguage);
    //   window.localStorage.setItem('lang', newLanguage);
    // }

  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

// get text according to id & current language
// export const Text = ({ tid }) => {
//   const languageContext = useContext(LanguageContext);
//   return languageContext.dictionary[tid] || tid;
// };
export const Text = ({ tid }) => {
  const { dictionary } = useContext(LanguageContext);
  return dictionary[tid] || tid;
};

