import React, { useContext, createContext, useState, useEffect } from 'react';

// Создаем контекст
export const DatabaseContext = createContext();

export const DatabaseProvider = ({ children }) => {
    const [db, setDb] = useState(null);

    // Открытие базы данных при монтировании компонента
    useEffect(() => {
        const openDB = () => {


            //     const deldb =  indexedDB.deleteDatabase("RestaurantDB")

            //    deldb.onsuccess = () => {
            //         console.log("База данных удалена");
            //     }

            let request = indexedDB.open("RestaurantDB", 1);
            request.onupgradeneeded = function (event) {
                let db = event.target.result;
                // Создание хранилищ данных
                // Если объектное хранилище "users" еще не существует
                if (!db.objectStoreNames.contains("users")) {
                    const userStore = db.createObjectStore("users", { keyPath: "uid" });
                    // Создаем индекс для поля "email"
                    userStore.createIndex("email", "email", { unique: true });
                    userStore.createIndex("current", "current", { unique: false });

                } else {
                    // Получаем объектное хранилище и создаем индекс, если его еще нет
                    const userStore = event.target.transaction.objectStore("users");
                    if (!userStore.indexNames.contains("email")) {
                        userStore.createIndex("email", "email", { unique: true });
                        userStore.createIndex("current", "current", { unique: false });
                    }
                }

                if (!db.objectStoreNames.contains("messages")) {
                    const messagesStore = db.createObjectStore("messages", { keyPath: "messageId" });
                    // Создаем индекс для поля "email"
                    messagesStore.createIndex("from", "from", { unique: false });
                    messagesStore.createIndex("to", "to", { unique: false });
                } else {
                    // Получаем объектное хранилище и создаем индекс, если его еще нет
                    const messagesStore = event.target.transaction.objectStore("messages");
                    if (!messagesStore.indexNames.contains("from")) {
                        messagesStore.createIndex("from", "from", { unique: false });
                        messagesStore.createIndex("to", "to", { unique: false });
                    }
                }

                if (!db.objectStoreNames.contains("Pos")) {
                    db.createObjectStore("pos", { keyPath: "pid" });
                }
                if (!db.objectStoreNames.contains("companies")) {
                    db.createObjectStore("companies", { keyPath: "companyId" });
                }
                if (!db.objectStoreNames.contains("kitchen")) {
                    db.createObjectStore("kitchen", { keyPath: "kid" });
                }
                if (!db.objectStoreNames.contains("menus")) {
                    db.createObjectStore("menus", { keyPath: "menuId" });
                }
                if (!db.objectStoreNames.contains("dishes")) {
                    db.createObjectStore("dishes", { keyPath: "did" });
                }
                if (!db.objectStoreNames.contains("ingredients")) {
                    db.createObjectStore("ingredients", { keyPath: "ingredientId" });
                }
            };

            request.onsuccess = (event) => {
                setDb(event.target.result);
            };

            request.onblocked = (event) => {
                console.log("База данных заблокирована:", event.target.error);
            };

            request.onerror = (event)=>  {
                console.error("Ошибка открытия базы данных:", event.target.error);
            };
        };

        openDB();
    }, []);

    // Пример функции для добавления данных в IndexedDB
    const addUser = (user) => {
        if (!db) return;
        const transaction = db.transaction(["users"], "readwrite");
        const store = transaction.objectStore("users");
        store.add(user);
        transaction.oncomplete = () => console.log("Пользователь добавлен");
        transaction.onerror = (e) => console.error("Ошибка:", e);
    };

    const getUsers = () => {
        return new Promise((resolve, reject) => {
            if (!db) return reject("База данных не готова");
            const transaction = db.transaction(["users"], "readonly");
            const store = transaction.objectStore("users");
            const request = store.getAll();

            request.onsuccess = (event) => resolve(event.target.result);
            request.onerror = (event) => reject(event.target.error);
        });
    };

    const getCurrentUser = () => {
        return new Promise((resolve, reject) => {
            console.log("getCurrentUser");
            if (!db) return reject("База данных не готова");
            const transaction = db.transaction(["users"], "readonly");
            const store = transaction.objectStore("users");
            const currentIndex = store.index("current"); // = store.getAll();
            const request = currentIndex.get("true");
            request.onsuccess = (event) => resolve(event.target.result);
            request.onerror = (event) => reject(event.target.error);
        });
    };


    const getUserById = (userId) => {
        return new Promise((resolve, reject) => {
            if (!db) return reject("База данных не готова");
            const transaction = db.transaction(["users"], "readonly");
            const store = transaction.objectStore("users");
            const request = store.get(userId);

            request.onsuccess = (event) => {
                if (event.target.result) {
                    resolve(event.target.result);
                } else {
                    reject("Пользователь не найден");
                }
            };
            request.onerror = (event) => reject(event.target.error);
        });
    };

    const getUserByEmail = (email) => {
        return new Promise((resolve, reject) => {
            if (!db) return reject("База данных не готова");
            const transaction = db.transaction(["users"], "readonly");
            const store = transaction.objectStore("users");

            // Используем индекс для поиска пользователя по email
            const emailIndex = store.index("email");
            const request = emailIndex.get(email);

            request.onsuccess = (event) => {
                if (event.target.result) {
                    resolve(event.target.result);
                } else {
                    reject("Пользователь с таким email не найден");
                }
            };

            request.onerror = (event) => reject(event.target.error);
        });
    };

    return (
        <DatabaseContext.Provider value={{ addUser, getUsers, getUserById, getUserByEmail, getCurrentUser }}>
            {children}
        </DatabaseContext.Provider>
    );
};


export const useDb = () => {
    return useContext(DatabaseContext);
};