import { Container, Grid, List, Box, ListItem, Typography, useTheme, TextField ,ListItemButton, Toolbar, IconButton} from '@mui/material'
import axios from 'axios'
import {Fragment, useEffect, useState} from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Text } from "../../languages/languages";
import SaveIcon from '@mui/icons-material/Save';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';



export const Templates = (pros) => {
    const theme = useTheme() //useTheme()
    const [templatesList, SetTemplatesList] = useState([])
    const [refresh, SetRefresh] = useState(true)
    const [error, setError] = useState(null);
    const [currentTemplate, SetCurrentTemplate] = useState(null)
    const [isNew, setIsNew] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
 
  


const handleAddTemplate = () => {
    if (!isEditing) {    
    SetCurrentTemplate({TemplateName:"", HtmlPart:"", SubjectPart:"", TextPart:"" })
    setIsEditing(true)
    setIsNew(true)
}
  }


  const handleEditClick = (template) => {
    if (!isEditing) {
    axios.get("https://v25mu5gut8.execute-api.eu-central-1.amazonaws.com/snsTemplateGet", {params: {
        TemplateName: template.Name
      }}).then(res => {  
        SetCurrentTemplate(res.data);
    })
      .catch((error) => {console.log(error); setError(error) })
    } 
  }

const DeleteHandler = async() => {
    if (!isNew) {
        await axios.post('https://v25mu5gut8.execute-api.eu-central-1.amazonaws.com/snsTemplateDelete', {TemplateName:currentTemplate.TemplateName})
        .then( data => {
            console.log(data)
            SetRefresh(true)
        })
        .catch((error) => {console.log(error); setError(error) } )
    } 
    setIsNew(false)
    setIsEditing(false)
    SetCurrentTemplate(null)
}

const SaveHandler = async () => {
    if (isEditing) { 
    const template = {
        TemplateName: currentTemplate.TemplateName,
        SubjectPart: currentTemplate.SubjectPart,
        TextPart: currentTemplate.TextPart, 
        HtmlPart: currentTemplate.HtmlPart
    }
    const Template = JSON.stringify(template)

     axios.post('https://v25mu5gut8.execute-api.eu-central-1.amazonaws.com/snsTemplateSave', {Template}, Headers,
    ).then( data => {
    //   console.log(data)
      setIsNew(false)
      setIsEditing(false)
      SetRefresh(true)
      SetCurrentTemplate(null)

    }).catch((error) => {console.log(error); setError(error) })    
    
} 
}

useEffect(()=>{
    const ReadTemplateList = async () => {
        axios.get("https://v25mu5gut8.execute-api.eu-central-1.amazonaws.com/snsTemplatelist")
        .then(res=>{
            console.log(res.data)
            SetTemplatesList(res.data)
        })
        .catch((error) => {console.log(error); setError(error) })
    }

    if (refresh) { 
        SetRefresh(false)
        ReadTemplateList()
    }   

    // console.log(currentTemplate)
},[refresh, currentTemplate])

    return (
        <Fragment>
            <Container mx = {0} background={theme.palette.background} color = {theme.palette.primary} sx={{ justifyContent:'center', flexDirection:'row' }}  >
           {error?<Toolbar> <Box display='flex' flexGrow={1}><Typography variant='h4'>{error}</Typography></Box> <IconButton onClick={()=>setError(null)}><CloseIcon/></IconButton> </Toolbar>:<></>}
           <Grid mt={8} ml={(0)} container spacing={0}  sx={{ height:'100vh'}} >
             <Grid item xs={3} >
                <Toolbar sx={{display:'flex', flexGrow: 1}} >
                <Typography variant='h6'> <Text tid="Templates"/></Typography>  
                <IconButton onClick={handleAddTemplate}>
                    <AddCircleOutlineIcon />
                </IconButton>
                <IconButton onClick={()=>SetRefresh(true)}>
                    <  RefreshIcon />
                </IconButton>
              

                </Toolbar>
            
             <List>
                { templatesList.length > 0 ? templatesList.map((template) => 
                    <ListItem key={template.Name}>  
                    <ListItemButton selected = {currentTemplate? currentTemplate.TemplateName===template.Name:false} onClick={()=>handleEditClick(template)}><Typography> {template.Name} </Typography> </ListItemButton>
                  </ListItem>
                ): <div key={1}></div>
                }
                </List>
             </Grid>
             <Grid item xs={9}>

            {currentTemplate?
        <Box>  
        <Toolbar  sx={{display:'flex', flexGrow: 1}} >
        <IconButton onClick={SaveHandler}><SaveIcon /></IconButton>
        <IconButton onClick={DeleteHandler}> <DeleteOutlineIcon/></IconButton>
        </Toolbar>
            <TextField  
                sx={{mb:2}} 
                fullWidth   
                    InputLabelProps={{
                    shrink: true,
                    }}
                value={currentTemplate.TemplateName} 
                label="Template name"
                placeholder="enter template" 
                onChange={e=>{
                    SetCurrentTemplate({...currentTemplate, TemplateName:e.target.value})
                        }} />

  <TextField sx={{mb:2}} 
  fullWidth 
  id = "subject"  
  value={currentTemplate.SubjectPart} 
  placeholder="enter email sublect" 
     InputLabelProps={{
        shrink: true,
      }}
  label="Subject"   
  onChange={(e)=>{
        SetCurrentTemplate({...currentTemplate, SubjectPart:e.target.value})
        setIsEditing(true)
    }} />
  
  <TextField  sx={{mb:2}} id = "text"  
  InputLabelProps={{
    shrink: true,
  }}
  value={currentTemplate.TextPart}  label="Text Template" 
  placeholder="enter text email body" 
  multiline={true} minRows={3}  fullWidth   onChange={(e)=>{
        SetCurrentTemplate({...currentTemplate, TextPart:e.target.value})
        setIsEditing(true)
    }} />

  <TextField sx={{mb:2}} id = "html" InputLabelProps={{
        shrink: true,
      }}  
      placeholder="enter  HTML email body" 
      value={currentTemplate.HtmlPart} label="HTML Template" multiline={true} minRows={10} fullWidth   onChange={(e)=>{
        SetCurrentTemplate({...currentTemplate, HtmlPart:e.target.value})
        setIsEditing(true)
    }} /> </Box>: <h1>Select</h1>}
            </Grid> 
            </Grid>     
            </Container>
        </Fragment>
    )
}