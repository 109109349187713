import en from './en.json'; // Английский
import ru from './ru.json'; // Русский
import uk from './uk.json'; // Украинский
import de from './de.json'; // Немецкий
import pl from './pl.json'; // Польский
import fr from './fr.json'; // Французский
import es from './es.json'; // Испанский
import it from './it.json'; // Итальянский
import nl from './nl.json'; // Нидерландский
import pt from './pt.json'; // Португальский
import cs from './cs.json'; // Чешский
import sk from './sk.json'; // Словацкий
// import hu from 'hu.json'; // Венгерский
// import bg from 'bg.json'; // Болгарский
// import ro from 'ro.json'; // Румынский
// import hr from 'hr.json'; // Хорватский
// import lt from 'lt.json'; // Литовский
// import lv from 'lv.json'; // Латвийский
// import et from 'et.json'; // Эстонский
// import sl from 'sl.json'; // Словенский
// import mt from 'mt.json'; // Мальтийский

export const dictionaryList = {
  en,
  ru,
  uk,
  de,
  pl,
  fr,
  es,
  it,
  nl,
  pt,
  cs,
  sk,
  
};
//  hu,
  // bg,
  // ro,
  // hr,
  // lt,
  // lv,
  // et,
  // sl,
  // mt

// export const languageOptions = {
//   en: 'English',
//   de: 'Deutsch ',
//   fr: 'Français',
//   pl: 'Polszczyzna',
//   ru: 'Русский',
//   uk: 'Українська',
// };

export const languageOptions = {
  
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  es: 'Español',
  pt: 'Português',
  en: 'English',
  // pl: 'Polski',
  uk: 'Українська',
  // nl: 'Nederlands',
  // cs: 'Čeština',
  // sk: 'Slovenčina',
  // hu: 'Magyar',
  // bg: 'Български',
  // ro: 'Română',
  // hr: 'Hrvatski',
  // lt: 'Lietuvių',
  // lv: 'Latviešu',
  // et: 'Eesti',
  // sl: 'Slovenščina',
  // mt: 'Malti',
  ru: 'Русский'
};
