import { createContext, useEffect, useState, useContext } from "react";
import { useLanguage } from "../languages/languages";
import { useDb } from "./DatabaseContext";

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const language = useLanguage();

    // Function to set user data and update localStorage
    const SetUserData = (key, value) => {
        try {
            const updatedUser = { ...user, [key]: value };
            setUser(updatedUser);
            localStorage.setItem("user", JSON.stringify(updatedUser));

        } catch (e) {
            console.error('Failed to save user data:', e);
        }
    };

    // Effect to initialize the user data from localStorage
    useEffect(() => {
        const onLanguageChange = () => {
            try {
                if (typeof language.userLanguageChange === 'function') {
                    // Use optional chaining to safely access user.language
                    language.userLanguageChange(user.language || "en");
                } else {
                    console.error('language.userLanguageChange is not a function');
                }
            } catch (error) {
                console.error(`Error: ${error}`);
            }
        };
        onLanguageChange()
    }, [language]);


    useEffect(() => {
        const initUser = () => {
            try {
                const storedUser = JSON.parse(localStorage.getItem("user")) || {};
                setUser(storedUser);
            } catch (e) {
                console.error(e);
            }
        };
        initUser();
    },[])

    return (
        <UserContext.Provider value={{ user, SetUserData }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider };
export const useUser = () => useContext(UserContext);
