import './App.css';
import { lazy, useMemo, Suspense } from "react"
import { Routes, Route } from "react-router-dom";
import { AppBar, ThemeProvider, useMediaQuery, CssBaseline, Container, Typography } from '@mui/material';
import { light, dark } from "./context/theme"
import { Templates } from '@page/admin/templates';
import { MyToolbar } from '@page/AppBar';
import Landing from '@page/landing';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import { LogoBig, LogoBigRainbow } from "./functions/SvgIcons";
import { Text } from './languages/languages';



const Login = lazy(() => import('@page/login'));
const GoogleOAuthProviderWrapper = lazy(() => import('@page/GoogleOAuthProviderWrapper'));
const Desktop = lazy(() => import('@page/desktop'));
const Error = lazy(() => import('@page/error'));
const Code = lazy(() => import('@page/codeinput'));
const SettingsForm = lazy(() => import('@page/settings'));
const RegisterForm = lazy(() => import('@page/registration'));
const NewPosForm = lazy(() => import('@page/newPOS'));
const DesktopLayout = lazy(() => import('@page/DesktopLayout'))


const App = () => {
  const isDark = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(() => { return isDark ? dark : light }, [isDark]);
  const SuspenseFallback = () => {
    <Container m={0} background={theme.palette.background}
      color={theme.palette.primary}
      sx={{ height: '100vh', justifyContent: 'center', flexDirection: 'row' }}  >
      <Typography variant="h1" sx={{ justifyContent: 'center', mt: "30vh" }} color={"primary"}>
        {isDark ? <LogoBig Color={'#f0f0f010'} /> : <LogoBigRainbow />}
        {/* <LogoBigRainbow Color={'#00ffff'}/> */}
      </Typography>
      <Typography variant="h6" sx={{ justifyContent: 'center' }} color={"primary"}>
        <Text tid="Loading..." />
      </Typography>
    </Container>
  };


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <div className="App">
        <AppBar
          elevation={0} mt={0} color='background'
          position="fixed"
          enableColorOnDark={true}
          sx={{ display: 'flex', flexGrow: 1, margin: 0, padding: 0 }} >
          <MyToolbar />
        </AppBar>
        <Suspense fallback={<SuspenseFallback />}>
          <Routes>
            <Route index element={<Landing />} />
            {/* <Route path="/" element={ <Landing />} /> */}
            <Route path="/sign" element={
              <GoogleOAuthProviderWrapper>
                <Login />
              </GoogleOAuthProviderWrapper>
            } />
            <Route path="/code/:data?" element={
              <Code />
            } />
            <Route path="/error" element={
              <Error />
            } />

            <Route path="/registration" element={
              <RegisterForm />} />

            <Route path="/desktop" element={<DesktopLayout />}>
              <Route index element={<Desktop />} />
              <Route path="users" element={<div><h2>Users</h2><p>This is the Users section.</p></div>} />
              <Route path="messages" element={<div style={{
                height: '100vh', 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <h2>Messages</h2>
                <p>This is the Messsages section.</p></div>} />
              <Route path="marketing" element={<div><h2>Marketing</h2><p>This is the Marketing section.</p></div>} />
              <Route path="new_pos" element={<NewPosForm />} />


              <Route path="settings" element={<SettingsForm />} />
            </Route>
            <Route path="/templates" element={<Templates />} />

          </Routes>
        </Suspense>
      </div>

    </ThemeProvider>
  );
}

export default App;




